<template>
  <div :class="classes">
    <VIcon
      v-if="isSelected"
      color="success"
      class="message__icon"
    >
      done
    </VIcon>

    <div @click="$emit('selectMessage', message.id)">
      <div class="message__inner">
        <div class="message__content">
          <MarkdownContent :content="message.body" />
          <ul v-if="message.files">
            <li
              v-for="file in message.files"
              :key="file.uuid"
            >
              <a
                :href="`/${file.uri}`"
                target="_blank"
              >
                {{ file.title || file.name }}
              </a>
            </li>
          </ul>
          <div class="message__date">
            <time>{{ createdAt }}</time>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UDate } from '@/utils/date'
import MarkdownContent from '@/components/MarkdownContent.vue'

export default {
  name: 'ChatMessage',
  components: { MarkdownContent },

  props: {
    message: {
      type: Object,
      required: true
    },

    currentMessageId: {
      type: Number,
      default: 0
    }
  },

  computed: {
    classes () {
      return {
        message: true,
        'message--outgoing': this.message.is_sender,
        'message--incoming': !this.message.is_sender,
        'message--selected': this.isSelected
      }
    },

    createdAt () {
      return UDate.formatDateTime(this.message.created_at)
    },

    isSelected () {
      return this.message.id === this.currentMessageId
    }
  }
}
</script>

<style lang="scss">
$icon-margin: 1rem;

.message {
  margin: 0.25rem 0;
  display: flex;

  p {
    margin-bottom: $spacer;
  }

  &:last-of-type {
    .message__inner::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
    }
  }
}

.message__inner {
  position: relative;
  padding: 0.5rem;
  min-width: 12rem;
  max-width: 25rem;
  background-color: currentColor;
}

.message__content {
  font-size: 0.875rem;
  color: #000;

  a {
    color: inherit;
  }
}

.message--outgoing {
  justify-content: flex-start;

  .message__icon {
    margin-right: $icon-margin;
    margin-left: 0;
  }

  .message__inner {
    color: rgba(black, 0.12);
    border-radius: 4px 4px 4px 0;

    &::before {
      right: 100%;
      border-right: 0 solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid currentColor;
    }
  }
}

.message--incoming {
  justify-content: flex-end;

  .message__icon {
    margin-left: $icon-margin;
    margin-right: 0;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    justify-content: flex-start;

    .message__icon {
      margin-right: $icon-margin;
      margin-left: 0;
    }
  }

  .message__content {
    color: var(--v-text-base);
  }

  .message__inner {
    color: var(--v-background-lighten1);
    border-radius: 4px 4px 0;

    &::before {
      left: 100%;
      border-left: 0 solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid currentColor;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      border-radius: 4px 4px 4px 0;

      &::before {
        left: auto;
        right: 100%;
        border-right: 0 solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid currentColor;
      }
    }
  }
}

.message__date {
  opacity: 0.8;
  color: inherit;
  font-size: 0.75rem;
}
</style>
